

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import ChangeDate from "@/components/changeDateComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent";
/**
 * Advanced-table component
 */
export default {
  page: {
    title: "รับอะไหล่เข้าคลัง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader , ChangeDate, Multiselect, DatePicker, btnComponent},
  data() {
    return {
      optionStatus:[
        {
          status: 'APV',
          name: ' ยืนยันการรับแล้ว '
        },
        {
          status: 'DFT',
          name: ' ร่าง '
        }
      ],
      loading: undefined,

      title: "รับอะไหล่เข้าคลัง",
      items: [
        {
          text: "อะไหล่",
        },
        {
          text: "รับอะไหล่เข้าคลัง",
          active: true,
        },
        
      ],
      
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      per_page: "",
      total_pages: "",
      rowpage: "",
      totalRecord: "",
      rowRC: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      localDataBranch:[],
      DateSearch:'',
      branchId:'',
      isSelectedAll: false,
      branchIdSearch:'',
      receiptCode:'',
      status:'',
      fields: [
        {
          label:"รหัสการรับ",
          key: "receiptCode",
          sortable: true,
        },
        {
          label: "สาขา",
          key: "branchName",
          sortable: true,
        },
        {
          label: "วันที่",
          key: "receiptDate",
          sortable: true,
        },
        {
          label: "สถานะ",
          key: "status",
          sortable: true,
        },
        {
          key: "action",
          label: "แก้ไข | ลบ"
        }
        
      ],
    };
  },

  computed: {
    rows() {
      return this.rowRC.length;
    },
  },
  mounted() {
    this.rowRC = this.items.length;
  },
  created() {
    this.getLocalData();
    this.startIndex = 1;
    this.endIndex = this.perPage;
    this.paginatedData = this.rowRC.slice(this.startIndex, this.endIndex);
    this.getData();
    
  },
  methods: {
    getReportReceipt(receiptId) {
     
      this.overlayFlag = true;
      useNetw
        .get("api/part-receipt/print", {		
          params: {
            receiptId: receiptId,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          this.linkPtintFlowslot  = data.data.url;
          window.location = (data.data.url);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    delRC(receiptId){
      useNetw
        .delete("api/part-receipt/delete", {				
          data: {
            receiptId: receiptId,
            
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    confirndelRC(rowsRC){
      // // console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการลบการนำอะไหล่เข้าคลังใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยิืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delRC(rowsRC);
        }
      });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // // console.log(user);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowRC = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowRC) {
      this.selected = rowRC;
      // this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getData: function () {
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-receipt", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: [this.branchId] ,
            receiptDate: this.DateSearch,
            status: this.status.status,
            receiptCode: this.receiptCode,

          },
        })
        .then((response) => {
          this.rowRC = response.data.data;
          this.per_page = response.data.per_page;
          this.page = response.data.page;
          this.totalRecord = response.data.total;
          this.total_pages = response.data.total_pages;
          this.id = response.data.id;

          // console.log(response.data.data[0]);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    ChangePage(page) {
      this.currentPage = page;
      this.getData();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowRC.slice(this.startIndex, this.endIndex);
    },
    PageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowRC.slice(this.startIndex, this.endIndex);
    },
    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
    },
    onSearch(){
      if(this.branchIdSearch === ''){
        this.branchId = this.localDataBranch[0];
      }else{
        this.branchId = this.branchIdSearch.branchId;
      }
      
      // // console.log(this.branchId);
      this.getData();
    }
  },
  middleware: "authentication",
};
</script><template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!--  -->
            <br />
            <div class="row">
              
              <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    
                    <label for="branchId">สาขา :</label><br />
                    <multiselect
                      v-model="branchIdSearch"
                      :options="localData"
                      label="nameTh"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <label >วันที่ :</label>
                    <date-picker
                      v-model="DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    
                    <label for="branchId">สถานะ :</label><br />
                    <multiselect
                      v-model="status"
                      :options="optionStatus"
                      label="name"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <label >รหัสใบรับ :</label>
                    <input 
                    type="text" 
                    class="form-control" 
                    placeholder="รหัสการสั่งซื้อ" 
                    v-model="receiptCode">
                  </div>
                </div>

                <div class="col-md-9 float-end text-end">
                  <label for="">&nbsp; :</label><br>
                  <btnComponent @click="onSearch()" changeStyle="search"></btnComponent> &nbsp;
                  <router-link
                    :to="{ name: 'add-recieve' }"
                    class="btn btn-primary float-end"
                    style="border:0"
                  >
                    <i class="mdi mdi-plus me-1"></i>เพิ่ม
                  </router-link>
                </div>
            </div>
            <div class="row float-end">
              
            </div>
            <!-- <p class="card-title-desc">รายการรับอะไหล่เข้าคลัง</p> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="PageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                   :</label>
                </div>
              </div>
              <!-- Search -->
              <!-- <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                   :</label>
                </div>
              </div> -->
              <div></div>
              <!-- End search -->
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  v-if="rowRC && rowRC.length > 0 && !isLoading"
                  id="table-transition-userList"
                  :key="rowRC.id"
                  :items="rowRC"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :select-mode="selectMode"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                  >

                  <template #cell(receiptDate)="rowRC">
                    <changeDate :date="rowRC.item.receiptDate"></changeDate>
                  </template>

                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template #cell(action)="rowRC">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'edit-recieve',
                          params: { receiptId: rowRC.item.receiptId },
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="confirndelRC(rowRC.item.receiptId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <i
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        @click="getReportReceipt(rowRC.item.receiptId)"
                        title="Print"
                      >
                        <i class="uil uil-print font-size-18"></i>
                      </i>
                    </li>
                  </ul>
                  
                </template>
                <template #cell(status)="rowRC">
                    <span
                      class="badge bg-warning font-size-12 ms-2"
                      v-if="rowRC.item.status === 'DFT'"
                    >
                      ร่าง
                    </span>
                    <span
                      class="badge bg-success font-size-12 ms-2"
                      v-if="rowRC.item.status === 'APV'"
                    >
                      ยืนยันการรับแล้ว
                    </span>
                  </template>
                  <!-- End edit table -->
                </b-table>

                <!-- <b-button v-model="selected" size="sm" @click="selectAllRows"
                  >Select all</b-button
                >
                &nbsp;

                <b-button size="sm" @click="clearSelected"
                  >Clear selected</b-button
                > -->

                <!-- <p>
                  Selected Rows:<br />
                  {{ selected }}
                </p> -->
              </div>
              <div class="row">
                <div class="col">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล {{ startIndex }} to {{ endIndex }} of
                    {{ totalRecord }} รายการ
                   :</label>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecord"
                        :per-page="perPage"
                        @change="ChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


